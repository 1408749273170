import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { Alert, Box, Button, CopyToClipboard, Flashbar, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import EnterTemplateDetailsPanel from './EnterTemplateDetailsPanel';
import { getOnboardTemplateSimUrl } from './GetSimUrl';
import Paths from '../../../../Paths';
import templateManagementClient from '../../../../api/templateManagement/TemplateManagementClient';
import { UPLOAD_TEMPLATE } from '../../../../api/templateManagement/TemplateManagementMutations';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import rumClient from '../../../../common/monitoring/RumClient';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../../common/RequestStatusFlashbar';
import { clearAppHelpPanel, updateAppHelpPanel } from '../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

type FinalizeTemplatePanelProps = AuthContextInterface & AppLabelsContextInterface;
const FinalizeTemplatePanel: FunctionComponent<FinalizeTemplatePanelProps> = ({ auth, appLabels }): JSX.Element => {
    const isAuthenticated = auth?.isAuthenticated();
    const userId = auth?.getUserInfo()?.userId;
    const createTemplateState = useAppSelector((state) => state.createTemplateState);
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [errorText, setErrorText] = useState<string>('');
    const [simUrl, setSimUrl] = useState<string>('');

    // Upload the template when pressing "submit". Show error in RequestStatusFlashbar
    const [uploadTemplate, { loading }] = useMutation(UPLOAD_TEMPLATE, {
        client: templateManagementClient,
        onError: (error) => {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.manage_templates.wizard.finalize.failed_to_upload,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const templateId = data.uploadTemplate;
            if (templateId) {
                setSimUrl(getOnboardTemplateSimUrl(templateId, createTemplateState.templateName, userId));
            }
        },
    });

    const errorAlert = useCallback(() => {
        return errorText ? (
            <Alert type='error' data-testid='alert-error'>
                {errorText}
            </Alert>
        ) : null;
    }, [errorText]);

    const successFlashbar = useCallback(() => {
        return simUrl ? (
            <Flashbar
                data-testid='flashbar-success'
                items={[
                    {
                        type: 'success',
                        content: (
                            <div>
                                {appLabels.manage_templates.wizard.finalize.successful_upload_text}
                                <Link href={simUrl} color='inverted' target='_blank' rel='noopener noreferrer' external={true} data-testid='link-sim'>
                                    {appLabels.manage_templates.wizard.finalize.successful_upload_linked_text}
                                </Link>
                            </div>
                        ),
                    },
                ]}
            />
        ) : null;
    }, [
        simUrl,
        appLabels.manage_templates.wizard.finalize.successful_upload_linked_text,
        appLabels.manage_templates.wizard.finalize.successful_upload_text,
    ]);

    // Go back to template preview
    const backButton = (
        <Button onClick={() => history.goBack()} data-testid='btn-back'>
            {appLabels.user_actions.back}
        </Button>
    );

    const copyTemplateButton = (
        <CopyToClipboard
            data-testid='btn-copy-template'
            copyButtonText={appLabels.manage_templates.wizard.finalize.copy_template}
            copyErrorText={appLabels.manage_templates.wizard.finalize.copy_template_fail}
            copySuccessText={appLabels.manage_templates.wizard.finalize.copy_template_success}
            textToCopy={JSON.stringify(createTemplateState.template, undefined, 4)}
        />
    );

    // Submit template to Template Management -- show an error if the form is incomplete. Need to check if template exists too,
    // since this page is URL-accessible
    const onCreateDraft = useCallback(() => {
        rumClient.recordClick(`${Paths.FINALIZE_TEMPLATE_PATH}/btn-submit`);

        if (!createTemplateState.template) {
            setErrorText(appLabels.manage_templates.wizard.finalize.no_template_imported);
            return;
        }

        if (!(createTemplateState.templateName && createTemplateState.templateDescription && createTemplateState.templateAcronym)) {
            setErrorText(appLabels.manage_templates.wizard.enter_details.must_fill_out_all_required_fields);
            return;
        }

        setErrorText('');

        // Upload the template to template management
        if (isAuthenticated) {
            uploadTemplate({ variables: { input: { template: JSON.stringify(createTemplateState.template) } } });
            rumClient.recordClick(`${Paths.FINALIZE_TEMPLATE_PATH}/submit-success`);
        }
    }, [
        createTemplateState,
        setErrorText,
        isAuthenticated,
        uploadTemplate,
        appLabels.manage_templates.wizard.enter_details.must_fill_out_all_required_fields,
        appLabels.manage_templates.wizard.finalize.no_template_imported,
    ]);
    const createDraftButton = (
        <Button variant='primary' onClick={onCreateDraft} loading={loading} data-testid='btn-submit'>
            {appLabels.user_actions.create_draft}
        </Button>
    );

    useEffect(() => {
        dispatch(
            updateAppHelpPanel({
                header: appLabels.manage_templates.wizard.finalize.help_panel.header,
                content: appLabels.manage_templates.wizard.finalize.help_panel.content,
            })
        );

        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        return cleanup;
    }, [dispatch, appLabels.manage_templates.wizard.finalize.help_panel]);

    return (
        <div className='awscat-applayout-wizard awscat-assessment-wrapper' style={{ width: '75rem' }} data-testid='finalize-template-panel'>
            <SpaceBetween size='m'>
                <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
                <EnterTemplateDetailsPanel />
                {successFlashbar()}
                {errorAlert()}
                <Box float='right'>
                    <SpaceBetween size='xs' direction='horizontal'>
                        {backButton}
                        {copyTemplateButton}
                        {createDraftButton}
                    </SpaceBetween>
                </Box>
            </SpaceBetween>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(FinalizeTemplatePanel));
